
.title{
    text-align: center;
    font-weight: bold;
    font-size: medium;
    padding: 2px;
    margin: 2px;
    
}
.card__body{
    padding: 5px;
    
}
.card__body image{


    animation-name: animatemodal;
    animation-duration: 10s;

}

@keyframes animatemodal {
    from{
        
        opacity:0;
    }
    to{
        
        opacity: 1;
    }
    
}

