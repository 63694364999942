.logo{
    /* width: 40px; */
    margin: 5px 0px 5px 1%;
}
.image_name{
    display: flex;
    color: white;
    /* color:black; */
    font-weight: bold;
    align-items: center;
    justify-content: center;
    
}
.title_name{
    padding-left: 20%;
    padding-right: 20%;
    justify-content: center;
    align-items: center;
}
a{
    color: white;
    text-decoration: none;
    
}

.links{
    padding-top: 5%;
    margin-left: 1px;
    padding-right: 1%;
    
}



@media screen and (min-width :0px){

    .link_home{
        padding-left: 50px;
    }
    
}
@media screen and (min-width :600px){

    .link_home{
        padding-left: 250px;
    }
    
}

@media screen and (min-width :992px){
    .link_home{
        padding-left: 300px;
    }
    
}