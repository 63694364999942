.container{
    background-color: rgb(248, 247, 247);
    padding: 10px;
}

.container h2{
    text-align: center;
}
.welcome{
    padding: 5px;
}