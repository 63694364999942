.program{
    text-align: center;
    font-weight: bold;
    padding: 2px;
    margin: 2px;
    
}
.benefit{
    
        text-align: center;
        font-weight: bold;
        padding: 2px;
        margin: 2px;
        
    
}
li{
    text-align: left;
    /* padding-left: 20px; */
    padding-top: 2%;

}

.card__body img{
    width:200px;
    height: auto;
    animation-name: animatemodal;
    animation-duration: 10s;

}





@keyframes animatemodal {
    from{
       
        opacity:0;
    }
    to{
        
        opacity: 1;
    }
    
}

