.enquiry_title{
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}
.containered {
    background-color: white;
    margin-top: 1px;
    margin-bottom: 10px;
    max-width: 100%;
    border: solid dimgrey ;
}
.ui.grid {
    margin-top: -1rem;
    margin-bottom: -1rem;
    /* margin-left: -1rem; */
    margin-right: -1rem;
}


.field{
    padding: 20px;
    text-align: center;
}

.ui.form .field>label{
    font-size: 20px;
}

.imageWP{
    margin-left: 15px;
    
    
}
.btn_wp{
    display: "flex";
    justify-content: center;
    align-items: center;
    /* margin-left: 80px; */
    margin-bottom: 10px;
    text-align: center;
}
.wpBtnInside{
    /* style={{backgroundImage:`url(${wp})` ,backgroundSize:"cover",width:"150px",height:'40px'}} */
   width: 150px;
   height: 40px;
   
}

.btn_wp{
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }